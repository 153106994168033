import React, { useState } from "react";
import Header from "../header/Header";
import axios from "axios";

const DeactivateAccount = () => {
  const [formData, setFormData] = useState({
    email: "",
    comment: "none",
  });

  const [successState, setSuccessState] = useState(false);

  // TRACKS THE INPUT VALUE
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // SUBMITS THE FORM
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `https://pxn-api-zzpou.ondigitalocean.app/api/v2/account/deactivate/`,
        formData,
        {
          timeout: 1000 * 60,
          headers: {
            "content-type": "application/json",
          },
        }
      );

      setSuccessState(true);
    } catch (err) {
      console.log(err);
    //   setSuccessState(true);
    }
  };
  return (
    <>
      <Header />

      <section className="flex flex-col mt-5 text-center px-10">
        <p className="font-bold text-5xl">
          Are you sure you want to Deactivate?
        </p>
      </section>

      <section
        id=""
        className="flex px-10 gap-3 grid grid-cols-1 md:grid-cols-4 mt-20 items-center mb-10"
      >
        <div className="">
          {/* <img src="" className="scale-95 " alt="IMG" /> */}
        </div>

        <div className="flex flex-col items-center justify-center col-span-2">
          {successState ? (
            <section className="bg-green-200 px-5 py-5">
              {" "}
              <p className="font-medium text-xl">
                Your request to deactivate has been submitted successfully and a
                confirmation link has been sent to your registered email
                address. 🎉
              </p>
            </section>
          ) : (
            <>
              <div className="border-l border-l-[#25A9E0] pl-8">
                <p className="font-medium text-xl">Steps to deactivate</p>
                <ol className="text-lg mt-6 list-disc">
                  <li>
                    Enter your registered email address below, and an optional
                    comment to let us know the reason for your decision.
                  </li>
                  <li>
                    A unique link would be sent to your registered email
                    address.
                  </li>
                  <li>
                    Click on link sent to email to completes the deactivation
                    process or ignore if you have a change of mind 😊
                  </li>
                </ol>
              </div>

              <div className="border-l border-l-[#25A9E0] pl-8 mt-6">
                <p className="font-medium text-xl">What we would keep</p>
                <ol className="text-lg mt-6 list-disc">
                  <li>
                    Your account information would be retained for a period of 7
                    days in the event you wish to reactivate, after which your
                    details would not be retrievable.
                  </li>
                  <li>
                    Note that any subsequent signups with the deactivated email
                    would be blocked
                  </li>
                </ol>
                <p className="text-lg mt-6 ">we retain the right to keep:</p>
                <ol className="text-lg mt-6 list-disc">
                  <li>
                    History of purchases and other transactions made while you
                    were an active user the PXN platform.
                  </li>
                  <li>
                    History of chats/conversation made while you were an active
                    user on the PXN platform
                  </li>
                  <li>
                    Email associated with recently deactivated account for
                    further security checks.
                  </li>
                </ol>
                <form class="max-w mx-auto" onSubmit={onSubmit}>
                  <label
                    for="email-address-icon"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your Email
                  </label>
                  <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                      <svg
                        class="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 16"
                      >
                        <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                        <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      id="email-address-icon"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="name@flowbite.com"
                      required
                      onChange={onChange}
                      name="email"
                    />
                  </div>
                  <label
                    for="message"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your message
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Leave a comment..."
                    onChange={onChange}
                    name="comment"
                  ></textarea>
                  <div class="flex items-start mb-5 mt-3">
                    <div class="flex items-center h-5">
                      <input
                        id="terms"
                        type="checkbox"
                        value=""
                        class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                        required
                      />
                    </div>
                    <label
                      for="terms"
                      class="ms-2 text-sm font-medium text-gray-900"
                    >
                      I have read and understood the terms outlined above
                    </label>
                  </div>
                  <button
                    type="submit"
                    class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                  >
                    Deactivate Account
                  </button>
                </form>
              </div>
            </>
          )}
        </div>
        <div className="">
          {/* <img src="" className="scale-95 " alt="IMG" /> */}
        </div>
      </section>
    </>
  );
};

export default DeactivateAccount;
