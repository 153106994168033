import React from "react";
import { appStore, appStore2, col } from "../../assets/images";

const IntroSection = () => {
  const appStoreLink = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.pxn.pxnewallet&hl=en&gl=US",
      "_blank"
    );
  };
  const appleStoreLink = () => {
    window.open(
      "https://apps.apple.com/ng/app/pxn-ewallet/id6444544053",
      "_blank"
    );
  };
  return (
    <section
      id="intro"
      className="grid grid-cols-1 md:grid-cols-2 gap-[10px] transition-all duration-500 px-10 mt-5"
    >
      <div className="flex flex-col justify-center ">
        <p className="md:leading-[70px] text-4xl md:text-6xl font-extrabold text-[#0F172A]">
          Exchange at your fingertips
        </p>

        <p className="text-[#0F172A] text-base md:text-lg mt-6">
          Introduce your network to the fastest, safest, and most reliable
          exchange platform. Experience seamless, secure transactions and
          embrace the future of efficiency and trust.
        </p>

        <div className="flex w-full mt-6 gap-2 md:gap-5">
          <img
            onClick={appleStoreLink}
            className="hover:scale-105 w-24 md:w-[163px] cursor-pointer"
            src={appStore}
            alt="APP STORE LOGO"
          />
          <img
            onClick={appStoreLink}
            className="hover:scale-105 w-24 md:w-[163px] cursor-pointer"
            src={appStore2}
            alt="APP STORE LOGO"
          />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <img className="rounded-full" src={col} alt="IPHONE" />
      </div>
    </section>
  );
};

export default IntroSection;
