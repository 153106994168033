import React from "react";
import { logo } from "../../assets/images/logo";
import { insta, social, x } from "../../assets/icons";

const Footer = () => {
  const xLink = () => {
    window.open("https://twitter.com/pxn_ewallet", "_blank");
  };
  return (
    <footer className="px-10 mt-10 border-t border-t-[#E2E8F0]" id="footer">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-20 mt-12 pb-8 border-b border-b-[#E2E8F0]">
        <div className="flex flex-col">
          <img className="w-16 h-12" src={logo} alt="" />

          <p className="text-[#475569] text-sm mt-3">
            Address: Office 13, No. 1 D.B Zang Rd, 930213, Jos
          </p>
        </div>

        <div className="flex flex-col">
          <p className="font-semibold text-lg">Quick Links</p>
          <ul className="flex font-medium mt-3">
            <li className=" mr-2 md:mr-[48px]">Home</li>
            <li className=" mr-2 md:mr-[48px]">Services</li>
            <li className=" mr-2 md:mr-[48px]">About</li>
            <li className=" mr-2 md:mr-[48px]">Features</li>
          </ul>
        </div>
      </div>

      <div className="mt-10 mb-10 flex md:justify-between flex-col md:flex-row ">
        <p className="text-[#475569]">All rights reserved @ PXN 2023.</p>

        <div className="flex mt-5 md:mt-0 gap-6">
          <img
            className="hover:scale-105 cursor-pointer"
            src={social}
            alt="SOCIAL MEDIA ICON"
          />
          <img
            className="hover:scale-105 cursor-pointer"
            src={insta}
            alt="SOCIAL MEDIA ICON"
          />
          <img
            onClick={xLink}
            className="hover:scale-105 cursor-pointer"
            src={x}
            alt="SOCIAL MEDIA ICON"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
