import React from "react";
import { iconBlue, iconRed, iconYellow } from "../../assets/icons";

const Services = () => {
  return (
    <section
      id="services"
      className="grid grid-cols-1 md:grid-cols-3 gap-[20px] px-10 mt-5 py-20"
      style={{ background: "rgba(37, 169, 224, 0.16)" }}
    >
      <div className="text-center flex flex-col items-center justify-start">
        <img src={iconRed} alt="ICONS" />

        <p className="font-bold text-2xl text-[#0F172A] mt-4">Logistics</p>
        <p className="text-lg text-[#0F172A] mt-4">
          Whether traveling a long or a short distance or having items delivered
          timely and safely on your doorsteps, pxn logistics covers you.
        </p>
      </div>
      <div className="text-center flex flex-col items-center justify-start">
        <img src={iconYellow} alt="ICONS" />

        <p className="font-bold text-2xl text-[#0F172A] mt-4">E-commerce</p>
        <p className="text-lg text-[#0F172A] mt-4">
          Enter our various markets and shop for multiple products from
          different vendors. Find endless products at affordable rates and the
          best part is; the items is delivered to you.
        </p>
      </div>
      <div className="text-center flex flex-col items-center justify-start">
        <img src={iconBlue} alt="ICONS" />

        <p className="font-bold text-2xl text-[#0F172A] mt-4">Utilities</p>
        <p className="text-lg text-[#0F172A] mt-4">
          Pay for your TV, internet subscriptions and other utilities.
        </p>
      </div>
    </section>
  );
};

export default Services;
