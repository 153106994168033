import React from "react";
import { mobile2 } from "../../assets/images";

const Mission = () => {
  return (
    <section id="about" className="px-10 gap-3 grid grid-cols-1 md:grid-cols-2 mt-20">
      <div className="">
        <img src={mobile2} className="scale-95 " alt="IMG" />
      </div>

      <div className="flex flex-col items-center justify-center">
        <div className="border-l border-l-[#25A9E0] pl-8">
          <p className="font-medium text-xl">Our Mission</p>
          <p className="text-lg mt-6 ">
            Pxn is to serve as a substitute for exchange of paper or coin bills
            as a means of exchange for goods or services at every location
            within Nigeria and possibly its surroundings neighbors within and
            outside the African continent(s) in the future.
          </p>
        </div>

        <div className="border-l border-l-[#25A9E0] pl-8 mt-6">
          <p className="font-medium text-xl">Our Mission</p>
          <p className="text-lg mt-6 ">
            Pxn is to serve as a substitute for exchange of paper or coin bills
            as a means of exchange for goods or services at every location
            within Nigeria and possibly its surroundings neighbors within and
            outside the African continent(s) in the future.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Mission;
