import Features from "./components/features/Features";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import IntroSection from "./components/introSection/IntroSection";
import Mission from "./components/mission/Mission";
import Services from "./components/services/Services";

function App() {
  return (
    <div>
      <Header />
      <IntroSection />
      <Services />

      <section className="flex flex-col mt-5 text-center px-10">
        <p className="font-bold text-5xl">About PXN</p>
        <p className="text-lg mt-6">
          Create multiple payment point for your products and avoid the hassle
          of long queues in your store.
        </p>
      </section>

      <Mission />

      <Features />

      <Footer />
    </div>
  );
}

export default App;
