import React from "react";
import { handheld } from "../../assets/images";

const Features = () => {
  return (
    <section id="features" className="pl-10 pr-10 md:pr-0 mt-10">
      <p className="font-bold text-5xl text-center">Our Features</p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-5">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 place-items-center">
          <div className="">
            <p className="text-2xl">Transfer</p>
            <p className="text-lg">
              Enjoy instant funds transfer to friends and families to be used in
              varieties of vendor's store.
            </p>
          </div>

          <div className="">
            <p className="text-2xl">Pay for goods and services</p>
            <p className="text-lg">
              With PXN, payment for goods and services are made easier and
              quicker through fast transaction processing and rendering good
              customer service.
            </p>
          </div>

          <div className="md:col-span-2 col-span-1 flex md:justify-center ">
            <div className="md:w-[326px]">
              <p className="text-2xl">Deliveries</p>
              <p className="text-lg">
                Enjoy instant delivery and closly monitor your items delivery
                progress.
              </p>
            </div>
          </div>
        </div>

        <div className="">
          <img src={handheld} alt="Handheld" />
        </div>
      </div>
    </section>
  );
};

export default Features;
