import React, { useState } from "react";
import Header from "../header/Header";
import axios from "axios";

const DeleteAccount = () => {
  const [formData, setFormData] = useState({
    email: "",
    comment: "",
  });

  const [successState, setSuccessState] = useState(false);

  // TRACKS THE INPUT VALUE
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // SUBMITS THE FORM
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `https://pxn-api-zzpou.ondigitalocean.app/api/v2/account/deactivate/`,
        formData,
        {
          timeout: 1000 * 60,
          headers: {
            "content-type": "application/json",
          },
        }
      );

      setSuccessState(true);
    } catch (err) {
      console.log(err);
    //   setSuccessState(true);
    }
  };
  return (
    <>
      <Header />

    </>
  );
};

export default DeleteAccount;
