import React from "react";
import Header from "../header/Header";
import { FaBell } from "react-icons/fa";

const Tracking = () => {
  return (
    <>
      <Header />

      <div className="flex flex-col justify-center items-center h-screen gap-10">
        <div className="border rounded-lg shadow-lg w-96 p-6 text-center flex flex-col justify-center items-center h-40">
          <p className="text-slate-500 font-semibold text-3xl">Total Users</p>
          <p className="text-[#25A9E0] font-black text-4xl mt-5">100</p>
        </div>

        <div className="grid grid-cols-2 gap-10">
          <div className=" border rounded-lg shadow-lg w-60 p-3 text-center flex flex-col justify-center items-center h-40">
            <p className="text-slate-500 font-semibold text-xl">Male Users</p>
            <p className="text-blue-700 font-black text-2xl mt-5">100</p>
          </div>
          <div className=" border rounded-lg shadow-lg w-60 p-3 text-center flex flex-col justify-center items-center h-40">
            <p className="text-slate-500 font-semibold text-xl">Female Users</p>
            <p className="text-pink-700 font-black text-2xl mt-5">100</p>
          </div>
        </div>
      </div>

      <div className="absolute flex items-center top-24 left-5">
        <div className="rounded-full p-2 border border-gray-300 animate-pulse">
          <FaBell className="text-xl text-[#25A9E0]"/>
        </div>
        <p className="text-lg ml-2 text-[#25A9E0]">No Notifications</p>
      </div>
    </>
  );
};

export default Tracking;
