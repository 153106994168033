import React, { useState } from "react";
import { logo } from "../../assets/images/logo";
import { BiPhoneCall } from "react-icons/bi";
import { BsArrowRightShort } from "react-icons/bs";
import { TiThMenuOutline } from "react-icons/ti";
import { AiOutlineClose } from "react-icons/ai";
import { redirect } from "react-router-dom";


const Header = () => {
  const [hideNav, setHideNav] = useState(false);

  const tracking = () => {
    window.open("http://dashboard.pxnewallet.com", "_blank");
  };

  const goHome = () => {
    window.open("https://pxnewallet.com", "/"   
    );
  }

  const showNavClass =
    "absolute top-0 lef-0 h-[300px] bg-white w-[200px] flex flex-col shadow-lg rounded-b-lg";

  const onToggleNav = () => {
    setHideNav(!hideNav);
  };

  return (
    <header className="md:p-2 flex justify-between items-center border-b-[#E2E8F0] border">
      <div className="w-fit md:w-[314px]">
        <img
          className="w-10 md:w-[69px] h-10 md:h-[49px]"
          src={logo}
          alt="hello"
          onClick={goHome}
        />
      </div>
      <div
        className={`${
          hideNav ? showNavClass : "hidden justify-between items-center"
        } bg-white md:flex  transition-all duration-700`}
      >
        <ul className="md:flex font-medium mt-3  p-2 md:p-0">
          <li className="mr-[38px] mt-3 md:mt-0">
            <a href="#intro" className="text-no-decoration">
              Home
            </a>
          </li>
          <li className="mr-[38px] mt-3 md:mt-0">
            <a href="#services" className="text-no-decoration">
              Services
            </a>
          </li>
          <li className="mr-[38px] mt-3 md:mt-0">
            <a href="#about" className="text-no-decoration">
              About
            </a>
          </li>
          <li className="mr-[38px] mt-3 md:mt-0">
            <a href="#features" className="text-no-decoration">
              Features
            </a>
          </li>
        </ul>

        <div className="my-3 mx-2 flex items-end md:items-center h-full">
          <BiPhoneCall size={24} color="#25A9E0" />
          <p className="text-[#25A9E0] font-medium text-sm md:text-base">
            +234 (703) 986 7855
          </p>
        </div>
      </div>

      <div className="flex gap-4">
        <button
          onClick={tracking}
          className="border border-[#25A9E0] hover:bg-[] w-20 md:w-[119px] flex justify-center items-center py-0 md:py-2 px-1 md:px-3 rounded-lg text-[#25A9E0] text-xs md:text-base"
        >
          Log In <BsArrowRightShort size={24} />
        </button>
        <button className="bg-[#F6921E] text-[#FFFFFF] w-20 md:w-[131px] py-2 px-3 rounded-lg text-center text-xs md:text-base">
          Download
        </button>
      </div>

      <div
        className={`bg-white shadow-lg rounded-full p-2 hover:scale-110 md:hidden`}
        onClick={onToggleNav}
      >
        {hideNav ? <AiOutlineClose /> : <TiThMenuOutline />}
      </div>
    </header>
  );
};

export default Header;
